import { CartSuccessLink } from './cartSuccess.module.css';
import H2 from '../../../Blocks/H2/H2';
import Paragraph from '../../../Blocks/Paragraph/paragraph';
import LinkTo from '../../../Blocks/LinkTo/linkTo';
import React from 'react';

const CartSuccess = () => (
  <div style={{ marginBottom: '50px', maxWidth: '800px' }}>
    <H2>Благодарим за ваш заказ!</H2>
    <Paragraph>
      Время прибытия и статус заказа появится в
      <LinkTo className={CartSuccessLink} to="/profile"> профиле</LinkTo>
      {' '}
      через несколько минут.
    </Paragraph>
  </div>
);

export default CartSuccess;

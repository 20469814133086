import React, { useEffect } from "react";
import { vkPixelFindLocation } from "../../Utils/vkPixel";
import { PayedContainerStyle } from './payedContainer.module.css';

const PayedContainer = ({ children }) => {
    useEffect(() => {
        vkPixelFindLocation();
    }, []);

    return (
        <div className={PayedContainerStyle}>
            {children}
        </div>
    );
};

export default PayedContainer;

import React, { } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CartSuccess from '../components/Cart/CartGrid/CartSuccess/cartSuccess';
import PayedContainer from "../components/PayedContainer/payedContainer";

const PayedPage = () => {
    return (
        <Layout>
            <SEO title="Заказ оплачен" />
            <PayedContainer>
                <CartSuccess />
            </PayedContainer>
        </Layout>
    )
}

export default PayedPage
